import { gsap } from 'gsap';
import { CustomEase } from "./customEase";
import { scroll, animPlaying, toggleAnimPlaying } from './utils/constants';
gsap.registerPlugin(CustomEase);

export default class Header {
  static init() {
    this.header = document.querySelector('.c-header');
    this.menuButton = this.header.querySelector('.o-menu-btn');
    this.burger = this.header.querySelector('.o-menu-btn_burger');
    this.cross = this.header.querySelector('.o-menu-btn_cross');
    this.menu = document.querySelector('.c-menu');
    this.isMenuVisible = false;
    this.menuButton.addEventListener('click', () => this.toggleMenu());
    this.initSubMenus();
    this.initArchivesMenu();
    this.initIndexItems();

    CustomEase.create("voletMenu", "0.57, 0.12, 0, 1");
    CustomEase.create("linksMenu", "0.17, 0.29, 0, 1");
    CustomEase.create("bottomMenu", "0.07, 0.28, 0.01, 1");
    CustomEase.create("parentOut", "0.42, 0.01, 0.59, 0.58");
    CustomEase.create("parentIn", "0, 0.18, 0, 1");
    CustomEase.create("subIn", "0, 0, 0, 1");

    this.durationVolet = 1.2;
    this.delay1 = 0.15;
    this.delay2 = 0.5;

    this.durationBottom = 0.8;
    this.delay3 = 1.2;
    this.delay31 = this.delay3 + 0.1;
    this.delay32 = this.delay31 + 0.1;

  }

  static initArchivesMenu() {
    if (document.querySelector('.has-count')) {
      const countSpan = document.createElement('span');
      countSpan.classList.add('menu-item-count');
      countSpan.innerHTML = document.querySelector('[data-cpt="references"]').getAttribute('data-count');
      document.querySelector('.wp-menu-main > .has-count > a').appendChild(countSpan);
    }
  }

  static initIndexItems() {
    if (!document.querySelector('.has-index')) return;
    const countSpan = document.createElement('span');
    countSpan.classList.add('menu-item-count');
    countSpan.innerHTML = 5;
    document.querySelector('.wp-menu-main > .has-index > a').appendChild(countSpan);
  }

  static initSubMenus() {
    this.parentsMenu = document.querySelectorAll('.wp-menu-main > .menu-item-has-children');
    Array.prototype.forEach.call(this.parentsMenu, (parent) => {
      parent.children[0].removeEventListener('click', () => this.toggleSubMenu(parent));
      parent.children[0].addEventListener('click', () => this.toggleSubMenu(parent));
    });
  }

  static toggleMenu() {
    if (animPlaying) return;
    toggleAnimPlaying(true);
    !this.isMenuVisible ? this.showMenu() : this.closeMenu();
  }

  static showMenu() {
    this.isMenuVisible = true;
    this.header.classList.add('is-open');
    scroll.scrollTo(0,{duration:200});
    scroll.stop();
    this.burgerToCross();

    if (window.innerWidth < 1024) document.body.style.overflow = "hidden";

    
    // Ouverture du volet droit
    gsap.to(this.menu.querySelector('.c-menu_right'), { y: '0%', duration: this.durationVolet, ease:'voletMenu'});
    gsap.to(this.menu.querySelector('.c-menu_right .c-menu_right_inner'), { y: '0%', duration: this.durationVolet, ease:'voletMenu'});

    if (window.innerWidth > 1023) {
      // Ouverture du volet gauche
      gsap.to(this.menu.querySelector('.c-menu_left'), { y: '0%', duration: this.durationVolet, ease:'voletMenu', delay:this.delay1});
      gsap.to(this.menu.querySelector('.c-menu_left img'), { y: '0%', scale:1, duration: this.durationVolet, ease:'voletMenu', delay:this.delay1});
    }

    // Apparition des liens
    gsap.to(this.menu.querySelectorAll('.c-menu_main .wp-menu-main>.menu-item>a'), { y:'0%', rotationX:'0deg', opacity:1, duration: 1, ease:"linksMenu", delay: this.delay2, stagger: 0.06 });
    

    // Volet bottom
    setTimeout(() => this.menu.querySelector('.c-menu_sub').classList.add('is-visible'), 600);

    // Apparition CTA
    gsap.to(this.menu.querySelector('.c-menu_sub .o-cta-border'), { opacity: 1, duration: 0.5, ease:'linear', delay: this.delay3 });
    gsap.to(this.menu.querySelector('.c-menu_sub .o-cta-border'), { y: '0%', duration: this.durationBottom, ease:'bottomMenu', delay: this.delay3 });

    // Apparition menu legals
    gsap.to(this.menu.querySelector('.c-menu_bottom .wp-menu-legals'), { opacity: 1, duration: 0.5, ease:'linear', delay: this.delay31 });
    gsap.to(this.menu.querySelector('.c-menu_bottom .wp-menu-legals'), { y: '0%', duration: this.durationBottom, ease:'bottomMenu', delay: this.delay31 });

    // Apparition reseaux sociaux
    gsap.to(this.menu.querySelector('.c-menu_bottom .o-socials'), { opacity: 1, duration: 0.5, ease:'linear', delay: this.delay32 });
    gsap.to(this.menu.querySelector('.c-menu_bottom .o-socials'), { y: '0%', duration: this.durationBottom, ease:'bottomMenu', delay: this.delay32, onComplete:() => toggleAnimPlaying(false) });

  }

  static closeMenu() {
    this.isMenuVisible = false;
    setTimeout(() => this.header.classList.remove('is-open'), 600);
    this.crossToBurger();
    if (window.innerWidth < 1024) document.body.style.overflow = "initial";

    if (window.innerWidth > 1023) {
      // Fermeture du volet gauche
      gsap.to(this.menu.querySelector('.c-menu_left'), { y: '-100%', duration: this.durationVolet, ease:'voletMenu'});
      gsap.to(this.menu.querySelector('.c-menu_left img'), { y: '95%', scale:1.2, duration: this.durationVolet, ease:'voletMenu'});
    }

    // Fermeture du volet droit
    gsap.to(this.menu.querySelector('.c-menu_right'), { y: '-100%', duration: this.durationVolet, ease:'voletMenu', delay: this.delay1});
    gsap.to(this.menu.querySelector('.c-menu_right .c-menu_right_inner'), { opacity: 0, duration: 0.5, ease:'none', delay: this.delay1});
    gsap.to(this.menu.querySelector('.c-menu_right .c-menu_right_inner'), { y: '100%', duration: this.durationVolet, ease:'voletMenu', delay: this.delay1, onComplete:() => {
      gsap.set(this.menu.querySelector('.c-menu_right .c-menu_right_inner'), { opacity:1 });
      gsap.set(this.menu.querySelectorAll('.c-menu_main .wp-menu-main>.menu-item>a'), { y:'100%', rotationX:'-35deg', opacity:0 });
      gsap.set([this.menu.querySelector('.c-menu_sub .o-cta-border'), this.menu.querySelector('.c-menu_bottom .wp-menu-legals'), this.menu.querySelector('.c-menu_bottom .o-socials')], { opacity:0, y: '150px' });
      this.menu.querySelector('.c-menu_sub').classList.remove('is-visible');
      if (document.querySelectorAll('.c-menu_main .menu-item-has-children.is-open').length > 0) this.resetSubMenu(document.querySelector('.c-menu_main .menu-item-has-children.is-open'));
      scroll.start();
      toggleAnimPlaying(false);
    }});
  }

  static burgerToCross() {
    this.menuButton.classList.add('is-disable');
    gsap.to(this.burger, { opacity: 0, duration: 0.5, ease:'linear', onComplete:() => {
      this.cross.classList.add('is-enable');
      this.menuButton.classList.remove('is-disable');
    }});
  }

  static crossToBurger() {
    this.menuButton.classList.add('is-disable');
    this.cross.classList.remove('is-enable');

    gsap.to(this.cross, { opacity: 0, duration: 0.5, ease:'linear', onComplete:()=> {
      this.menuButton.classList.remove('is-disable');
      gsap.to(this.burger, { opacity: 1, duration: 0.5, ease:'linear' });
      gsap.set(this.cross, { opacity: 1 });
    }});
  }

  static toggleSubMenu(parent) {
    parent.classList.contains('is-open') ? this.hideSubMenu(parent) : this.openSubMenu(parent);
  }

  static openSubMenu(parent) {
    this.menu.querySelector('.wp-menu-main').style.pointerEvents = 'none';
    this.menu.querySelector('.c-menu_main').style.height = this.menu.querySelector('.c-menu_main').offsetHeight + 'px';
    gsap.to('.wp-menu-main > li', {opacity:0, duration: 0.36, ease: 'none'});
    gsap.to('.wp-menu-main', {x: '-10%', duration: 0.36, ease: 'parentOut', onComplete:() => {
      parent.classList.add('is-open');
      setTimeout(() => parent.classList.add('is-visible'), 400);
      gsap.fromTo(parent, { x:'20%', opacity: 0 }, { x: '10%', opacity:1, duration:0.83, ease: 'parentIn' });
      gsap.fromTo(parent.querySelectorAll('.menu-item'), { x:'10%', opacity: 0 }, { x: '0%', opacity:1, duration:1, ease: 'subIn', stagger: 0.06 });
    }});
  }

  static hideSubMenu(parent) {
    gsap.to(parent, {opacity:0, duration: 0.36, ease: 'none'});
    gsap.to(parent, {x:'20%', duration: 0.36, ease: 'parentOut', onComplete:() => {
      gsap.set(parent, { x:'0%' });
      gsap.to('.wp-menu-main > li', {opacity:1, duration: 0.83, ease: 'none'});
      gsap.to('.wp-menu-main', {x:'0%', duration: 0.83, ease: 'parentIn'});
      parent.classList.remove('is-open', 'is-visible');
      this.menu.querySelector('.wp-menu-main').style.pointerEvents = 'initial';
      this.menu.querySelector('.c-menu_main').style.height = 'auto';
    }});
  }

  static resetSubMenu(parent) {
    gsap.set([parent, '.wp-menu-main'], {x:'0%'});
    gsap.set('.wp-menu-main > li', {opacity:1, x:'0%'});
    parent.classList.remove('is-open', 'is-visible');
    this.menu.querySelector('.wp-menu-main').style.pointerEvents = 'initial';
    this.menu.querySelector('.c-menu_main').style.height = 'auto';
  }
}
