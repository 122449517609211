import SplitText from './utils/splittextgsap';

export default class CustomSplitText {
  static init() {
    if (document.querySelector('[data-split]') == null) return;
    this.all = document.querySelectorAll('[data-split]');
    for (let i = this.all.length - 1; i >= 0; i--) this.split(this.all[i]);
  }

  static split(text) {
    setTimeout(() => {
      let mySplitText = new SplitText(text, { type: text.getAttribute('data-split') });
      text.removeAttribute('data-split');
    }, 120);
  }
}
