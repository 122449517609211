import Testimonies from './testimonies';
import { gsap } from "gsap";
import { scroll } from './utils/constants';

export default class ScrollReveals {
  static init() {
    this.allText = Array.prototype.slice.call(document.querySelectorAll('[text-reveal="scroll"]'));
    this.partnersGrid = document.querySelector('.c-partners_grid');
    this.sliderTem = document.querySelector('.o-bloc-testimonies');
    this.quoteHead = document.querySelectorAll('.o-bloc-testimonies_head');
    this.linksList = document.querySelectorAll('.o-links-list_link');
    this.numbersList = document.querySelectorAll('.c-chiffres_single');

    this.gridLists = Array.prototype.slice.call(document.querySelectorAll('.c-gridlist_container'));
    scroll.on('scroll', () => this.checkPos());
  }

  static checkPos() {
    Array.prototype.forEach.call(this.allText, (e) => {
      if (e.getBoundingClientRect().top - 0.8 * window.innerHeight < 0) this.revealText(e);
    })

    if (this.partnersGrid) {
      if (this.partnersGrid.getBoundingClientRect().top - 0.8 * window.innerHeight < 0 && !this.partnersGrid.classList.contains('is-visible')) this.revealPartnersGrid();
    }

    if (this.sliderTem) {
      if (this.sliderTem.querySelector('.o-bloc-testimonies_right').getBoundingClientRect().top - 0.8 * window.innerHeight < 0 && !this.sliderTem.classList.contains('is-visible')) this.revealSliderTem();
    }

    Array.prototype.forEach.call(this.quoteHead, (e) => {
      if (e.getBoundingClientRect().top - 0.8 * window.innerHeight < 0 && !e.classList.contains('is-visible')) e.classList.add('is-visible');
    })

    Array.prototype.forEach.call(this.gridLists, (e) => {
      if (e.getBoundingClientRect().top - 0.8 * window.innerHeight < 0) this.revealGridList(e);
    })

    Array.prototype.forEach.call(this.linksList, (e) => {
      if (e.getBoundingClientRect().top - 0.8 * window.innerHeight < 0 && !e.classList.contains('is-visible')) e.classList.add('is-visible');
    })

    Array.prototype.forEach.call(this.numbersList, (e) => {
      if (e.getBoundingClientRect().top - 0.8 * window.innerHeight < 0 && !e.classList.contains('is-visible')) e.classList.add('is-visible');
    })
  }

  static revealText(text) {
    const index = this.allText.indexOf(text);
    if (index > -1) this.allText.splice(index, 1);
    gsap.to(text.children, { y: '0%', rotationX:'0deg', opacity:1, ease:'power4.out', duration: 1, stagger: 0.1 });
  }

  static revealPartnersGrid() {
    this.partnersGrid.classList.add('is-visible');
    gsap.to(this.partnersGrid.querySelectorAll('.c-partners_img'), { y: '0%', opacity:1.6, ease:'power4.out', duration: 1.2, stagger: 0.08});
  }

  static revealSliderTem() {
    this.sliderTem.classList.add('is-visible');
    const firstTem = this.sliderTem.querySelector('.o-bloc-testimonies_item.is-active');
    setTimeout(() => Testimonies.showNew(firstTem), 600);
  }

  static revealGridList(list) {
    const index = this.gridLists.indexOf(list);
    if (index > -1) this.gridLists.splice(index, 1);

    let delay = 0;
    Array.prototype.forEach.call(list.children, (item) => {
      gsap.delayedCall(delay, revealItem, [item]);
      delay += 0.4;
    })

    function revealItem(item) {
      item.classList.add('is-visible');
      let tl = gsap.timeline()
        .to(item.querySelector('.c-gridlist_text').children, {y: '0%', rotationX:'0deg', opacity:1, ease:'power4.out', duration: 1, stagger: 0.1}, "+=0.4")
        .to(item.querySelector('.c-gridlist_link'), {y:'0em', opacity:1, ease:'power4.out', duration: 1}, "-=0.6");
    }
  }
}
