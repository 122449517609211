import { gsap } from "gsap";
import { scroll } from './utils/constants';

export default class Footer {
  static init() {
    this.shapeContainer = document.querySelector('.c-footer_shape');
    this.shape = document.querySelector('.c-footer_shape svg');
    scroll.on('scroll', () => this.footerPos());
  }

  static footerPos() {
    const BottomPos = this.shapeContainer.getBoundingClientRect().top - window.innerHeight;
    if (BottomPos < 0 && window.innerWidth > 599) this.rotateShape();
  }

  static rotateShape() {
    const rotation = 25 * (this.shapeContainer.getBoundingClientRect().bottom - window.innerHeight) / this.shapeContainer.clientHeight;
    const translate = 20 * (this.shapeContainer.getBoundingClientRect().bottom - window.innerHeight) / this.shapeContainer.clientHeight + 50;
    gsap.set(this.shape, {rotate:rotation+'deg', x:translate+'%'});
  }
}
