import { gsap } from "gsap";

export default class CookiesBanner {
  static init() {
    localStorage.getItem('cookiesNovatlas') == null ? this.showBanner() : this.hideBanner();
  }

  static showBanner() {
    document.querySelector('.cookies').style.pointerEvents = "all";
    gsap.to('.cookies', {opacity:1, duration: 0.4, ease:'none', delay:5});
    document.querySelector('.cookies_close').addEventListener('click', () => this.hideBanner());
  }

  static hideBanner() {
    if (localStorage.getItem('cookiesNovatlas') == 'ok') {
      if (document.querySelector('.cookies')) document.body.removeChild(document.querySelector('.cookies'));
    } else {
      gsap.to('.cookies', {opacity:0, duration: 0.3, ease:'none', onComplete:() => {
        document.body.removeChild(document.querySelector('.cookies'));
        localStorage.setItem('cookiesNovatlas', 'ok');
      }});
    }
  }
}
