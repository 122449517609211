import LocomotiveScroll from 'locomotive-scroll';
import { gsap } from 'gsap';
import { CustomEase } from "./../customEase";
import lottie from 'lottie-web';
gsap.registerPlugin(CustomEase);

export const scroll = new LocomotiveScroll({
  el: document.querySelector('[data-scroll-container]'),
  smooth: true
});

let animPlaying = false;
const toggleAnimPlaying = (bool) => { animPlaying = bool }
export { animPlaying, toggleAnimPlaying };

let firstLaunch = true;
const toggleFirstLaunch = () => { firstLaunch = false }
export { firstLaunch, toggleFirstLaunch };

const easeVolet = CustomEase.create("volet", "0.5, 0, 0, 1");
const easeMaskVolet = CustomEase.create("maskVolet", "0.70, 0, 0.01, 1");
const homeReveal = CustomEase.create("homeReveal", "0.11, 0.4, 0, 1");
export { easeVolet, homeReveal, easeMaskVolet };

export const lottieShutter = lottie.loadAnimation({
  container: document.querySelector('.lottie-shutter'),
  renderer: 'svg',
  loop: false,
  autoplay: false,
  // initialSegment: [61, 121],
  path: document.querySelector('.lottie-shutter').getAttribute('data-path')
});

lottieShutter.addEventListener('DOMLoaded', waveReady);
function waveReady(){
  document.querySelector('.lottie-shutter svg').setAttribute('preserveAspectRatio', 'none');
  document.querySelector('.lottie-shutter').style.backgroundColor = "transparent";
}
