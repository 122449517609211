import Init from './init';
import Intros from './../intros';
import Header from './../header';
import lottie from 'lottie-web';
import { scroll, toggleAnimPlaying, easeVolet, easeMaskVolet, lottieShutter } from './constants';
import { LoaderJS } from './loader';
import { gsap } from 'gsap';

export default class Ajax {
  static init() {
    this.onPopState();
    this.ajaxContent = "#main";
    this.mediasLoaded = [];
    this.currentCount = 0;
    this.totalCount = 0;

    this.ajaxLayout = document.querySelector('.transition');
    this.ajaxInner = document.querySelector('.transition-inner');
    this.lottiesContainer = document.querySelector('.lotties-container');

    this.initLottie();
    document.body.addEventListener('click', (e) => this.handler(e));
  }

  static initLottie() {
    this.lottieComplete = false;
    this.lottieLogo = lottie.loadAnimation({
      container: document.querySelector('.lottie-ajax'),
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: document.querySelector('.lottie-ajax').getAttribute('data-path')
    });
    this.lottieLogo.addEventListener('complete', () => {
      this.lottieComplete = true;
      this.isReady();
    });
  }

  static handler(e) {
    this.link = e.target.closest('a');
    if (this.link !== null) { this.href = e.target.closest('a').getAttribute('href') } else { return false; }

    /* Nothing */
    if(this.href == null || this.href == '#' || this.href === document.location || e.target.hasAttribute('data-anchor') || this.link.parentNode.classList.contains('menu-item-has-children')) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }

    /* Other */
    if(this.href.indexOf('mailto:')!=-1) return;
    if(this.href.indexOf('tel:')!=-1) return;
    if(this.href.indexOf('facebook')!=-1) return;
    if(this.href.indexOf('instagram')!=-1) return;

    /* New tab */
    if (this.link.getAttribute('target') == '_blank') return;

    /* Page change */
    // OLD // if(this.href.indexOf(document.domain) > -1 || this.href.indexOf(':') === -1) {
    if(this.href.indexOf(location.protocol+'//'+document.domain) > -1 || this.href.indexOf(':') === -1) {
      this.pageChange(e);
      history.pushState({}, '', this.href);
    } 
  }

  static pageChange(e) {
    e.preventDefault();

    this.domReady = false;
    this.pageLeft = true;
    this.domReplaced = false;

    document.querySelector('.c-header').classList.contains('is-open') ? this.disableMenu() : this.pageLeave();
    this.ajaxLoad().then(() => {
      this.domReady = true;

      if (this.doc.querySelectorAll('[data-preload]').length > 0) {
        const sourcesMedias = [];
        Array.prototype.forEach.call(this.doc.querySelectorAll('[data-preload]'), (el, i) => { sourcesMedias[i] = el.getAttribute('data-preload') });
        this.preloadMedias(sourcesMedias);
      } else {
        this.isReady();
      }

    });
  }

  static disableMenu() {
    this.totalCount = 1;
    toggleAnimPlaying(true);
    document.getElementsByTagName('html')[0].classList.add('wait');
    document.querySelector('.c-header').style.pointerEvents = 'none';
  }

  /* Browser back button */
  static onPopState() {
    window.addEventListener('popstate', (e) => {
      this.href = location.href;
      this.pageChange(e);
    }, false);
  }

  /* Ready to launch ?*/
  static isReady() {
    this.currentCount++;
    if (this.domReady && this.pageLeft && !this.domReplaced){
      this.replaceContent().then(() => {
        Init.start();
        this.domReplaced = true;
        // if (this.currentCount === this.totalCount && this.domReplaced) setTimeout(() => this.pageIn(), 100);
        if (this.currentCount === this.totalCount && this.domReplaced) this.pageIn();
      });
    }

    if (this.currentCount === this.totalCount && this.domReplaced) {
      this.pageIn();
    } else if (this.lottieComplete) {
      this.currentCount--;
      this.lottieLogo.goToAndPlay(0,0);
    }
  }

  static pageLeave() {
    this.pageLeft = false;
    this.totalCount = 3;
    toggleAnimPlaying(true);
    document.getElementsByTagName('html')[0].classList.add('wait');

    lottieShutter.playSegments([0, 60]);
    gsap.delayedCall(1, () => this.lottieShutterComplete());
    gsap.delayedCall(0.5, () => this.lottieLogo.play());
    gsap.fromTo(this.ajaxInner, {y:'100%'}, {y:"0%", ease:easeMaskVolet });
    gsap.fromTo(this.lottiesContainer, {opacity: 1, y:'-70%'}, {y:"0%", ease:easeMaskVolet});
  }

  /* Load DOM */
  static ajaxLoad() {
    return new Promise((resolve) => {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", this.href, true);
      xhr.responseType = 'document';
      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 400) {
          this.doc = xhr.response;
          resolve();
        }
      };
      xhr.send();
    });
  }

  static preloadMedias(sources) {
    let mediasAlreadyLoaded = 0;
    const loader = new LoaderJS();
    for (let i = sources.length - 1; i >= 0; i--) {
      if (!this.mediasLoaded.some(row => row.includes(sources[i]))) {
        loader.loadMedia('myMedia'+i, sources[i]);
        this.mediasLoaded.push(sources[i]);
      } else {
        mediasAlreadyLoaded++;
      }
    }
    mediasAlreadyLoaded === sources.length ? this.isReady() : loader.whenReady = () => { this.isReady() };
  }

  static replaceContent() {
    return new Promise((resolve) => {
      document.querySelector(this.ajaxContent).parentNode.replaceChild(this.doc.querySelector(this.ajaxContent), document.querySelector(this.ajaxContent));
      // document.querySelector('.o-lang-switch').parentNode.replaceChild(this.doc.querySelector('.o-lang-switch'), document.querySelector('.o-lang-switch'));
      if (this.link.parentNode.classList.contains('lang-item')) {
        document.querySelector('.c-menu_right_inner').parentNode.replaceChild(this.doc.querySelector('.c-menu_right_inner'), document.querySelector('.c-menu_right_inner'));
        document.querySelector('.c-footer').parentNode.replaceChild(this.doc.querySelector('.c-footer'), document.querySelector('.c-footer'));
        if (document.querySelector('.cookies')) document.querySelector('.cookies').parentNode.replaceChild(this.doc.querySelector('.cookies'), document.querySelector('.cookies'));
        Header.initSubMenus();
        Header.initArchivesMenu();
      }
      document.body.className = this.doc.body.className;
      document.title = this.doc.title;
      resolve();
    });
  }

  static pageIn() {
    this.currentCount = 0;
    scroll.update();
    Intros.init();
    document.getElementsByTagName('html')[0].classList.remove('wait');

    if (document.querySelector('.c-header').style.pointerEvents == 'none') document.querySelector('.c-header').style.pointerEvents = 'all';
    if (document.querySelector('.c-header').classList.contains('is-open')) {
      Header.closeMenu();
      if (window.location.hash) scroll.scrollTo(document.querySelector(window.location.hash),{duration:1000, easeing:[1, 0, 0, 1]});
    } else {
      lottieShutter.playSegments([61, 121]);
      gsap.to(this.lottiesContainer, {y:"70%", duration:1, ease:easeMaskVolet});
      gsap.to(this.ajaxInner, {y:'-100%', duration:1, ease:easeMaskVolet, onComplete: () => {
        toggleAnimPlaying(false);
        this.resetLottie();
      }});

      if (window.location.hash) scroll.scrollTo(document.querySelector(window.location.hash),{duration:40, easeing:[0.7, 0, 0.1, 1.00]});
    }
  }

  static lottieShutterComplete() {
    this.pageLeft = true;
    this.isReady();
  }

  static resetLottie() {
    this.lottieLogo.goToAndStop(0,0);
    this.lottieComplete = false;
  }
}
