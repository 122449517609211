import { ready } from './js/utils/utils';
import Init from './js/utils/init';
import Intros from './js/intros';
import TouchDevice from './js/utils/touch-device';
import Ajax from './js/utils/ajax.js';
import Header from './js/header';
import Footer from './js/footer';
import FontFaceObserver from 'fontfaceobserver';
import { scroll } from './js/utils/constants';

ready()
  .then(() => {
    window.scrollTo(0,0);
    TouchDevice.init();
    Ajax.init();
    Header.init();
    Footer.init();
    Init.start();
    Intros.init();
    const font = new FontFaceObserver('Stratos', { weight: 700 });
    font.load().then( () => scroll.update());
  });
