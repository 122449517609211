import gsap from 'gsap';
import { tns } from 'tiny-slider/src/tiny-slider';

export default class Sliders {
  static init() {
    if (document.querySelector('.o-slider') == null) return;
    this.initModal();
    this.sliders = document.querySelectorAll('.o-slider');
    for (let i = this.sliders.length - 1; i >= 0; i--) { this.initSlider(this.sliders[i]) }
  }

  static initSlider(slider) {
    const tnsSlider = tns({
      container: slider.querySelector('.o-slider_slides'),
      items: 3,
      loop: false,
      autoplay: false,
      mouseDrag: true,
      gutter: 40,
      nav: false,
      controls: true,
      nextButton: slider.querySelector('.o-slider_next'),
      prevButton: slider.querySelector('.o-slider_prev'),
      responsive: {
        0: { items: 1.333, gutter: 20 },
        601: { items: 2 },
        1024: { items: 3 }
      }
    });

    this.updateProgress(slider, tnsSlider);
    tnsSlider.events.on('indexChanged', () =>  this.updateControls(slider, tnsSlider) );
    tnsSlider.events.on('transitionStart', () =>  this.updateProgress(slider, tnsSlider) );
    slider.addEventListener('click', (e) => {
      if (e.target.closest('button')) this.showModal(e.target.closest('button').parentNode);
    })
  }

  static updateControls(slider, tnsSlider) {
    const data = tnsSlider.getInfo();
    if (data.index > 0 && data.prevButton.classList.contains('is-disable')) data.prevButton.classList.remove('is-disable');
    if (data.index + data.items < data.slideCount && data.nextButton.classList.contains('is-disable')) data.nextButton.classList.remove('is-disable');
    if (data.index == 0) data.prevButton.classList.add('is-disable');
    if (data.index + data.items == data.slideCount) data.nextButton.classList.add('is-disable');
  }

  static updateProgress(slider, tnsSlider) {
    const percentage = (tnsSlider.getInfo().index + tnsSlider.getInfo().items) / tnsSlider.getInfo().slideCount;
    gsap.to(slider.querySelector('.o-slider_progressline'), {scaleX:percentage, duration:0.4, ease:'power4.out'});
  }

  static initModal() {
    if (document.querySelector('.o-slider_modal')) return false;
    
    this.modalContainer = document.createElement("div");
    this.modalContainer.className = 'o-slider_modal';

    this.modalCaption = document.createElement("label");
    this.modalCaption.className = 'o-slider_modal_caption';

    this.modalContainer = document.createElement("div");
    this.modalContainer.className = 'o-slider_modal';

    this.modalImg = document.createElement("img");

    this.modalCloseBtn = document.createElement("button");
    this.modalCloseBtn.className = 'o-slider_modal_close';

    this.modalPrevBtn = document.createElement("button");
    this.modalPrevBtn.className = 'o-slider_modal_prev';

    this.modalNextBtn = document.createElement("button");
    this.modalNextBtn.className = 'o-slider_modal_next';

    this.modalContainer.appendChild(this.modalCaption);
    this.modalContainer.appendChild(this.modalImg);
    this.modalContainer.appendChild(this.modalCloseBtn);
    this.modalContainer.appendChild(this.modalPrevBtn);
    this.modalContainer.appendChild(this.modalNextBtn);

    document.body.appendChild(this.modalContainer);

    this.modal = document.querySelector('.o-slider_modal');

    document.addEventListener('keydown', (e) => {
      if (e.keyCode == 27) this.hideModal();
      if (e.keyCode == 39) this.modalNext();
      if (e.keyCode == 37) this.modalPrev();
    });

    document.querySelector('.o-slider_modal_prev').addEventListener('click', () => this.modalPrev());
    document.querySelector('.o-slider_modal_next').addEventListener('click', () => this.modalNext());
    document.querySelector('.o-slider_modal_close').addEventListener('click', () => this.hideModal());
  }

  static showModal(current) {
    this.currentSlide = current;
    this.modal.querySelector('img').setAttribute('src', current.querySelector('img').getAttribute('src-large'));
    if (current.classList.contains('-caption')) this.modal.querySelector('.o-slider_modal_caption').textContent = current.querySelector('.o-slider_caption').textContent;
    this.modal.classList.add('is-visible');
    gsap.fromTo(this.modal.querySelector('img'), {scale:1.1, opacity:0}, {scale:1, opacity:1, duration:0.6, ease:'power4.out'});
  }

  static hideModal() {
    if (!this.modal.classList.contains('is-visible')) return false;
    this.modal.classList.remove('is-visible');
    setTimeout(() => this.modal.querySelector('img').setAttribute('src', ''), 200);
  }

  static modalNext() {
    this.currentSlide = this.currentSlide.nextElementSibling ? this.currentSlide.nextElementSibling : this.currentSlide.parentNode.firstElementChild;
    this.updateModal();
  }

  static modalPrev() {
    this.currentSlide = this.currentSlide.previousElementSibling ? this.currentSlide.previousElementSibling : this.currentSlide.parentNode.lastElementChild;
    this.updateModal();
  }

  static updateModal() {
    this.modal.querySelector('img').setAttribute('src', this.currentSlide.querySelector('img').getAttribute('src'));
    this.modal.querySelector('.o-slider_modal_caption').textContent = this.currentSlide.classList.contains('-caption') ? this.currentSlide.querySelector('.o-slider_caption').textContent : "";
  }
}
