import { getAutoHeight, indexInParent } from "./utils/utils";
import { gsap } from 'gsap';

export default class Testimonies {
  static init() {
    if (document.querySelector('.o-bloc-testimonies') == null) return;

    this.testimonies = document.querySelectorAll('.o-bloc-testimonies');
    this.container = document.querySelector('.o-bloc-testimonies_slides');
    this.playing = false;

    for (let i = this.testimonies.length - 1; i >= 0; i--) {
      this.setHeight(this.testimonies[i]);
      this.attachEvents(this.testimonies[i]);
    }
  }

  static initFirst(e) {
    const text = e.querySelector('.o-bloc-testimonies_item:first-of-type .o-bloc-testimonies_text p');
    const info = e.querySelector('.o-bloc-testimonies_item:first-of-type .o-bloc-testimonies_author');
    gsap.set(text.children, { y: '70%', opacity: 0 });
    gsap.set(info, { opacity: 0 });
  }

  static setHeight(e) {
    const slides = e.querySelectorAll('.o-bloc-testimonies_item');
    let maxHeight = 0;
    for (let i = slides.length - 1; i >= 0; i--) {
      maxHeight = getAutoHeight(slides[i]) > maxHeight ? getAutoHeight(slides[i]) : maxHeight;
    }
    this.container.style.height = maxHeight+'px';
  }

  static attachEvents(e) {
    e.querySelector('.o-bloc-testimonies_nav').addEventListener('click', (event) => {
      if (this.playing) { return } else { this.playing = true }
      event.target.hasAttribute('data-prev') ? this.prevTestimony(e) : this.nextTestimony(e);
    });
  }

  static prevTestimony(e) {
    const current = e.querySelector('.o-bloc-testimonies_item.is-active');
    const prevIndex = indexInParent(current) === 0 ? e.querySelectorAll('.o-bloc-testimonies_item').length - 1 : indexInParent(current) - 1;
    const prev = e.querySelectorAll('.o-bloc-testimonies_item')[prevIndex];
    const dir = "down";
    this.hideCurrent(current, dir).then(() => this.showNew(prev, dir));
  }

  static nextTestimony(e) {
    const current = e.querySelector('.o-bloc-testimonies_item.is-active');
    const nextIndex = indexInParent(current) === e.querySelectorAll('.o-bloc-testimonies_item').length - 1 ? 0 : indexInParent(current) + 1;
    const next = e.querySelectorAll('.o-bloc-testimonies_item')[nextIndex];
    this.hideCurrent(current).then(() => this.showNew(next));
  }

  static hideCurrent(current, dir="up") {
    const text = current.querySelector('.o-bloc-testimonies_text p');
    const info = current.querySelector('.o-bloc-testimonies_author');
    const to = dir === "up" ? "-70%" : "70%";
    const stagger = dir === "up" ? 0.08 : -0.08;
    return new Promise((resolve) => {
      gsap.fromTo(info, { opacity: 1 }, { opacity: 0, duration: 0.6 });
      gsap.fromTo(text.children, { y: '0%', opacity: 1}, { y: to, opacity: 0, duration: 0.5, stagger: stagger, ease:'power3.in', onComplete: () => {
        current.classList.remove('is-active');
        resolve();
      }});
    });
  }

  static showNew(newItem, dir="up") {
    const text = newItem.querySelector('.o-bloc-testimonies_text p');
    const info = newItem.querySelector('.o-bloc-testimonies_author');
    const from = dir === "up" ? "70%" : "-70%";
    const stagger = dir === "up" ? 0.08 : -0.08;
    gsap.fromTo(info, { opacity: 0 }, { opacity: 1, duration: 0.6 });
    gsap.fromTo(text.children, { y: from, opacity: 0 }, { y: '0%', opacity: 1, duration: 0.7, stagger: stagger, ease:'power4.out', onComplete: () => {
      newItem.classList.add('is-active');
      this.playing = false;
    }});
  }
}
