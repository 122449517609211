import ScrollReveals from './scroll-reveals';
import { gsap } from 'gsap';
import { animPlaying, toggleAnimPlaying, firstLaunch, toggleFirstLaunch, scroll, homeReveal, easeMaskVolet, lottieShutter } from './utils/constants';
import lottie from 'lottie-web';

export default class Intros {
  static init() {
    this.initLotties();
    firstLaunch ? this.firstLaunchIntro().then(() => this.launchIntros()) : this.launchIntros();
    ScrollReveals.init();
  }

  static launchIntros() {
    if (document.body.classList.contains('page-template-page-home')) this.introHome();
    setTimeout(() => {
      if (document.querySelector('[text-reveal="intro"]')) this.revealText(document.querySelectorAll('[text-reveal="intro"]'));
      // if (document.querySelector('[scale-reveal="intro"]')) this.revealScale(document.querySelectorAll('[scale-reveal="intro"]'));
    }, 200);
      
  }

  static initLotties() {
    this.lottieIntro = lottie.loadAnimation({
      container: document.querySelector('.lottie-intro'),
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: document.querySelector('.lottie-intro').getAttribute('data-path')
    });

    lottieShutter.goToAndStop(61, true);
  }

  static firstLaunchIntro() {
    toggleFirstLaunch();
    return new Promise((resolve, reject) => {
      this.lottieIntro.addEventListener('DOMLoaded',() => {
          window.innerWidth < 601 ? this.lottieIntro.playSegments([0, 80], true) : this.lottieIntro.play();
      });
      this.lottieIntro.addEventListener('complete', () => {
        resolve();
        lottieShutter.playSegments([61, 121], true);
        gsap.to('.transition-inner', {y:'-100%', duration:1, ease: easeMaskVolet});
        gsap.to('.lotties-container', {y:'70%', duration:1, opacity:0, ease: easeMaskVolet, onComplete:() => document.querySelector('.transition').classList.remove('is-intro')});
        if (window.location.hash) scroll.scrollTo(document.querySelector(window.location.hash),{duration:1000, easeing:[0.7, 0, 0, 1.00]});
      });
    });
  }

  static introHome() {
    // CustomEase.create("homeReveal", "0.11, 0.4, 0, 1");
    const deltaY = window.innerHeight - (document.querySelector('.c-hdg-hp_content').offsetHeight + document.querySelector('.c-header').offsetHeight);
    gsap.set('.c-hdg-hp_photo', { y: deltaY+"px" });
    gsap.set('.c-hdg-hp_photo_container', { y: -0.8*deltaY+"px" });
    gsap.to('.c-hdg-hp_photo, .c-hdg-hp_photo_container', { y: 0, ease:homeReveal, duration: 1.5, delay: 0.45 });
    document.querySelector('.c-hdg-hp_paragraph')?.classList.add('is-visible')
    gsap.to('.c-hdg-hp_cta', { y: 0, opacity:1, ease:'power4.out', duration: 1.4, delay: 1.2 });
  }

  static revealText(texts) {
    let delay = 0.4;
    for (var i = 0; i < texts.length; i++) {
      gsap.to(texts[i].children, { y: '0%', rotationX:'0deg', opacity:1, ease:'power4.out', duration: 1, stagger: 0.1, delay: delay });
      delay = delay+0.3;
    }
  }

  static revealScale(imgs) {
    for (var i = 0; i < imgs.length; i++) {
      gsap.fromTo(imgs[i], { scale:'1.'+imgs[i].getAttribute('data-scale')}, { scale: 1, ease:'power4.out', duration: 1.4, delay: 0.4 });
    }
  }
}
