import { scroll } from './constants';

export default class Forms {
  static init() {
    if (document.querySelectorAll('form').length < 1) return false;
    Array.prototype.forEach.call(document.querySelectorAll('.auto-expand'), (el) => this.autoExpandForm(el));
    document.querySelector('button[type="submit"]')?.addEventListener('click', (e) => this.checkForm(e));
  }

  static autoExpandForm(el) {
    const fontSize = 16;
    const padding = 2 * (1.5 * fontSize);
    const lineHeight = 1.75 * fontSize;
    const minRows = 9;
    el.addEventListener('keydown', () => {
      el.rows = 1;
      if (Math.round((el.scrollHeight - padding) / lineHeight) < minRows) {
        el.rows = minRows;
      } else {
        el.rows = Math.round((el.scrollHeight - padding) / lineHeight);
        scroll.update();
      }
    });
  }

  static mailOk(mailteste) {
    const reg = new RegExp('^[a-z0-9]+([_|\.|-]{1}[a-z0-9]+)*@[a-z0-9]+([_|\.|-]{1}[a-z0-9]+)*[\.]{1}[a-z]{2,6}$', 'i');
    if(reg.test(mailteste)) return(true);
    else return(false);
  }

  static checkForm(e) {

    e.preventDefault();

    if(document.querySelector('input[name="email"]').value !== "") {

      document.getElementsByTagName('html')[0].classList.add('wait');

      let formData;
      formData =  'c_name='+encodeURIComponent(document.querySelector('input[name="name"]').value);
      formData += '&c_firstname='+encodeURIComponent(document.querySelector('input[name="firstname"]').value);
      formData += '&c_email='+encodeURIComponent(document.querySelector('input[name="email"]').value);
      formData += '&c_sujet='+encodeURIComponent(document.querySelector('select[name="sujet"]').value);
      formData += '&c_message='+encodeURIComponent(document.querySelector('textarea[name="message"]').value);

      const request = new XMLHttpRequest();
      request.open('GET', '?alright&' + formData, true);
      request.onload = () => { request.status >= 200 && request.status < 400 ? this.showMessage('success') : this.showMessage('error'); }
      request.send();
    }
    return false;
  }

  static showMessage(handle) {
    document.getElementsByTagName('html')[0].classList.remove('wait');
    const message = handle == 'success' ? document.querySelector('.form-message').getAttribute('data-success') : document.querySelector('.form-message').getAttribute('data-error');
    let newP = document.createElement("p");
    newP.classList.add('form-confirmation');
    newP.innerHTML = message;
    document.querySelector('form').appendChild(newP);
    scroll.update();
  }
}
