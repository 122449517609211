import Testimonies from './../testimonies';
import CustomSplitText from './../split-text';
import Sliders from './../sliders';
import CookiesBanner from './cookies-banner';
import Form from './form';
import { scroll } from './constants';

export default class Init {
  static start() {
    window.scrollTo(0,0);
    scroll.scrollTo(0,{duration:0});
    CustomSplitText.init();
    Testimonies.init();
    Sliders.init();
    CookiesBanner.init();
    Form.init();
  }
}
