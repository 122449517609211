import { gsap } from 'gsap';

export function ready() {
  return new Promise((resolve) => {
    if (document.attachEvent ? document.readyState === 'complete' : document.readyState !== 'loading') {
      resolve();
    } else {
      document.addEventListener('DOMContentLoaded', event => resolve(event));
    }
  });
}

export function modulo(m, n) {
  return ((m % n) + n) % n;
}

export function indexInParent(node) {
  var children = node.parentNode.childNodes;
  var num = 0;
  for (var i=0; i<children.length; i++) {
    if (children[i]==node) {return num;}
    if (children[i].nodeType==1) {num++;}
  }
  return -1;
}

export function getAutoHeight(element) {
  const heightBackup = element.style.height;
  const displayBackup = element.style.display;
  element.style.height = 'auto';
  element.style.display = 'block';
  // const autoHeight = element.innerHeight;
  const autoHeight = element.offsetHeight;
  element.style.height = heightBackup;
  element.style.display = displayBackup;
  return autoHeight;
}

export function getScrollAmount() {
  return window.pageYOffset
    || document.documentElement.scrollTop
    || document.body.scrollTop
    || 0;
}

export function scrollTo(target, duration=0.8) {
  gsap.to("html, body", {duration: duration, scrollTop: document.getElementById(target).offsetTop, ease:'power4.inOut'});
}

export function toggleGrid() {
  document.addEventListener('keydown', function(event) {
    if (event.keyCode == 71) {
      document.querySelector('.grid').classList.toggle('visible');
    }
  }, false);
}

